import { Slider } from "@material-tailwind/react";
import React from "react";
import { FaShippingFast } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdAssignmentReturn } from "react-icons/md";
import { BiSolidCollection } from "react-icons/bi";
import SportsBanner from "../../assets/sports-banner.jpg";
import AdvertisementTwo from "../../assets/Run Eat Sleep and Repeat.jpg";
import AdvertisementOne from "../../assets/Mens running shoes advt.jpg";
import Shoe01 from "../../assets/01.jpg";
import Shoe02 from "../../assets/02.jpg";
import Shoe03 from "../../assets/03.jpg";
import Shoe04 from "../../assets/04.jpg";
import Shoe05 from "../../assets/05.jpg";
import Shoe06 from "../../assets/06.jpg";
import Shoe07 from "../../assets/07.jpg";
import Shoe08 from "../../assets/08.jpg";

const Home = () => {
  return (
    <div>
      <div className="bg-banner bg-no-repeat bg-cover pt-[120px] pb-40 px-0 md:py-60 md:px-40 text-white text-center">
        <h1 className="lg:text-[90px] md:text-[60px] font-semibold sm:text-[60px] text-[40px]">
          NIKE SHOES
        </h1>
        <p>SIGN UP NOW & GET 50% OFF</p>
      </div>

      <div className="w-[85%] mx-auto border-solid bg-white mt-[-50px] border-gray-300 border mb-0">
        <div className="grid lg:grid-cols-4 md:grid-cols-1">
          <div className="text-center px-10 pt-10 pb-16 border-solid border-gray-300 border-r md:border-b border-b sm:border-b text-gray-800">
            <FaShippingFast size={60} className="inline-block py-4" />
            <h2 className="lg:text-lg md:text-md font-semibold py-2">
              FAST SHIPPING
            </h2>
            <p className="pb-4">
              Fulfill your orders quickly and provides best shipping services
            </p>
            <button className="bg-red-900 px-4 py-2 text-white rounded-lg text-xs">
              Read More
            </button>
          </div>

          <div className="text-center px-8 pt-10 pb-16 border-solid border-gray-300 border-r md:border-b border-b sm:border-b text-gray-800">
            <RiCustomerService2Fill size={60} className="inline-block py-4" />
            <h2 className="lg:text-lg md:text-md font-semibold py-2">
              24X7 CUSTOMERCARE
            </h2>
            <p className="pb-4">
              Customer service representatives are available 24x7
            </p>
            <button className="bg-red-900 px-4 py-2 text-white rounded-lg text-xs">
              Read More
            </button>
          </div>

          <div className="text-center px-10 pt-10 pb-16 border-solid border-gray-300 md:border-b border-b sm:border-b border-r text-gray-800">
            <BiSolidCollection size={60} className="inline-block py-4" />
            <h2 className="lg:text-lg md:text-md font-semibold py-2">
              VAST COLLECTION
            </h2>
            <p className="pb-4">
              New collection of inventory arrives every week.
            </p>
            <button className="bg-red-900 px-4 py-2 text-white rounded-lg text-xs">
              Read More
            </button>
          </div>

          <div className="text-center px-10 pt-10 pb-16 border-solid border-gray-300 md:border-b border-b sm:border-b text-gray-800">
            <MdAssignmentReturn size={60} className="inline-block py-4" />
            <h2 className="lg:text-lg md:text-md font-semibold py-2">
              FREE RETURN
            </h2>
            <p className="pb-4">
              Shipping labels are available to return products at no cost.
            </p>
            <button className="bg-red-900 px-4 py-2 text-white rounded-lg text-xs">
              Read More
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="p-20 text-center content-center lg:col-span-2 col-span-3 bg-gray-200">
            <h3 className="py-4 font-semibold">OFFER OF THE MONTH</h3>
            <h1 className="font-bold text-3xl">NIKE AIR MAX 270</h1>
            <p className="pt-4">
              The Nike Air Max 270 maximizes the foot's natural motion where it
              is needed most. With light and dynamic performance this new
              basketball shoe features full length Nike Zoom dynamic Flywire and
              a flexible foam lining.
            </p>
            <div className="py-4">
              <span className="text-2xl font-semibold px-2">$139</span>
              <span className="text-2xl font-semibold px-2 line-through">
                $198
              </span>
            </div>
            <button className="bg-red-800 px-4 py-2 my-2 rounded-lg text-white text-sm">
              SHOP NOW
            </button>
          </div>
          <img
            className="col-span-1 hidden lg:block sm:hidden"
            width="auto"
            src={SportsBanner}
          />
        </div>
      </div>

      <div className="w-[85%] mx-auto pt-14">
        <h3 className="w-full block text-center font-semibold">NEW ARRIVALS</h3>
        <h1 className="text-center text-2xl py-4 font-bold">
          DISCOVER THE LATEST TRENDS NOW!
        </h1>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2">
          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe01} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$146</span>
              <span className="px-2 line-through">$177</span>
            </div>
          </div>

          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe02} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$187</span>
              <span className="px-2 line-through">$198</span>
            </div>
          </div>

          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe03} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$123</span>
              <span className="px-2 line-through">$147</span>
            </div>
          </div>

          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe04} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$96</span>
              <span className="px-2 line-through">$117</span>
            </div>
          </div>

          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe05} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$119</span>
              <span className="px-2 line-through">$138</span>
            </div>
          </div>

          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe06} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$137</span>
              <span className="px-2 line-through">$156</span>
            </div>
          </div>

          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe07} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$163</span>
              <span className="px-2 line-through">$197</span>
            </div>
          </div>

          <div className="mx-4 my-4 border-solid border-2 border-gray-200">
            <img width={320} src={Shoe08} />
            <div className="bg-gray-200 py-4 text-center">
              <span className="px-2">$75</span>
              <span className="px-2 line-through">$92</span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[85%] mx-auto pt-8 pb-12">
        <div className="grid lg:grid-cols-2 md:grid-cols-2">
          <div className="p-3 border-solid border-[0px] border-gray-400">
            <img width={700} src={AdvertisementOne} />
          </div>
          <div className="p-3">
            <img width={700} src={AdvertisementTwo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
