import React from "react";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { TiSocialGooglePlusCircular } from "react-icons/ti";
import { SlSocialYoutube } from "react-icons/sl";
import { TiSocialPinterestCircular } from "react-icons/ti";
import { TiSocialTumblerCircular } from "react-icons/ti";
import { TiSocialFlickrCircular } from "react-icons/ti";

const AppFooter = () => {
  return (
    <div className="bg-[#040404ec] text-gray-300">
      <div className="w-[85%] grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 grid-cols-2 mx-auto py-20">
        <div className="px-2">
          <h2 className="text-red-800 font-semibold pb-4">SHOPS</h2>
          <ul className="text-sm leading-loose">
            <li>New Arrivals</li>
            <li>Men</li>
            <li>Women</li>
            <li>Sport</li>
            <li>Accessories</li>
            <li>Top Brands</li>
          </ul>
        </div>

        <div className="px-2">
          <h2 className="text-red-800 font-semibold pb-4">INFORMATION</h2>
          <ul className="text-sm leading-loose">
            <li>About Us</li>
            <li>Best Sellers</li>
            <li>New Collection</li>
            <li>Manufactures</li>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
          </ul>
        </div>

        <div className="px-2 mt-6 lg:mt-0 md:mt-0 sm:mt-0">
          <h2 className="text-red-800 font-semibold pb-4">CUSTOMER SERVICE</h2>
          <ul className="text-sm leading-loose">
            <li>Store Locations</li>
            <li>Contact Us</li>
            <li>Help & FAQs</li>
            <li>Consultant</li>
            <li>Search Terms</li>
            <li>Orders & Returns</li>
          </ul>
        </div>

        <div className="px-2 mt-6 lg:mt-0 md:mt-0 sm:mt-0">
          <h2 className="text-red-800 font-semibold pb-4">STAY CONNECTED</h2>
          <span className="flex space-x-2 text-3xl">
            <TiSocialFacebookCircular className="cursor-pointer" />
            <TiSocialGooglePlusCircular className="cursor-pointer" />
            <SlSocialYoutube className="cursor-pointer" />
            <TiSocialPinterestCircular className="cursor-pointer" />
            <TiSocialTumblerCircular />
            <TiSocialFlickrCircular />
          </span>

          <span className="mt-6 flex flex-col">
            <h2 className="text-red-800 font-semibold pb-3">
              NEWSLETTER SIGN UP
            </h2>
            <input
              className="rounded-lg mb-4 h-[32px] p-4 text-sm"
              type="text"
              placeholder="Enter Email Address"
            />
            <button className="px-4 py-2 bg-red-500 text-white rounded-lg w-[100px] text-xs">
              SHOP NOW
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
