import "./App.css";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import Home from "./pages/Home";

function App() {
  return (
    <div className="App max-w-[1530px] mx-auto">
      <AppHeader />
      <div className="AppPageContent">
        <Home />
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
