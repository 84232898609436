import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { TiSocialGooglePlusCircular } from "react-icons/ti";
import { SlSocialYoutube } from "react-icons/sl";
import { FaShoppingBag } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { TiSocialPinterestCircular } from "react-icons/ti";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const AppHeader = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <header className="bg-[#040404ec] w-full lg:px-20 md:px-10 px-10 grid grid-cols-3 text-white">
      <div className="lg:col-span-2 py-3 flex sm:col-span-3 md:col-span-2 w-full">
        <img
          className="block cursor-pointer float-left lg:mx-0"
          src={Logo}
          width={90}
          height={10}
        />
        <nav className="text-sm hidden sm:block lg:block md:mx-auto md:pl-12">
          <ul className="flex space-x-6 lg:space-x-10 px-20 py-3 sm:px-4 sm:mx-auto">
            <li className="cursor-pointer">MEN</li>
            <li className="cursor-pointer">WOMEN</li>
            <li className="cursor-pointer">KIDS</li>
            <li className="cursor-pointer">SPORTS</li>
            <li className="cursor-pointer">BRANDS</li>
            <li className="cursor-pointer">CUSTOMIZE</li>
          </ul>
        </nav>

        {/* //Mobile Menu */}
        <div>
          <span
            onClick={handleNav}
            className="lg:hidden md:hidden sm:hidden xs:block absolute right-0 px-10 py-2 text-2xl"
          >
            {!nav ? <AiOutlineMenu size={25} /> : <AiOutlineClose size={25} />}
          </span>

          <div
            className={
              nav
                ? "fixed left-0 top-0 pt-16 w-[50%] h-full border-r border-r-gray-900 bg-[#040404] ease-in-out duration-500"
                : "fixed left-[-100%]"
            }
          >
            <ul className="px-6 leading-10 text-sm">
              <li className="cursor-pointer">MEN</li>
              <li className="cursor-pointer">WOMEN</li>
              <li className="cursor-pointer">KIDS</li>
              <li className="cursor-pointer">SPORTS</li>
              <li className="cursor-pointer">BRANDS</li>
              <li className="cursor-pointer">CUSTOMIZE</li>
            </ul>
          </div>
        </div>
        {/* //Mobile Menu End */}
      </div>

      <div className="col-span-1 hidden lg:flex md:flex px-6 lg:pr-4 sm:hidden xs:hidden md:content-center">
        <div className="space-x-8 hidden sm:hidden lg:flex justify-center flex-auto text-center text-[22px] px-4 py-6 border-solid border-gray-500 border-l">
          <TiSocialFacebookCircular className="cursor-pointer" />
          <TiSocialGooglePlusCircular className="cursor-pointer" />
          <SlSocialYoutube className="cursor-pointer" />
          <TiSocialPinterestCircular className="cursor-pointer" />
        </div>
        <div className="hidden lg:flex md:block md:mx-auto md:mr-0">
          <div className="space-x-8 sm:hidden xs:hidden md:block md:mx-auto lg:ml-0 justify-center p-7 text-[17px] py-6 border-solid border-gray-500 border-l border-r-[1px]">
            <FaShoppingBag className="cursor-pointer" />
          </div>
          <div className="p-6 text-[17px] hidden lg:block">
            <FaSearch className="cursor-pointer" />
          </div>
        </div>{" "}
      </div>
    </header>
  );
};

export default AppHeader;
